<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <section class="question-section section-gap-30">
                <div class="section-title-wrap">
                    <div class="d-flex justify-content-between">
                        <h2 class="section-title mr-2">{{ $t(`All Question Sets`) }}</h2>
                        <v-popover offset="10" :disabled="false" style="display: initial;">
                            <button class="ej__tooltip__button mlF-1"><i class="eicon e-question"></i></button>
                            <template slot="popover">
                                <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                <a v-close-popover href="https://easy.jobs/docs/create-question-set" target="_blank">
                                    {{ $t(`How to create a question set ?`) }}
                                </a>
                            </template>
                        </v-popover>

                    </div>
                    <form action="" @submit.prevent="" class="form-filter mr-auto mb-0 ml-5">
                        <div class="search-bar">
                            <input type="text"
                                   v-model="filter.name"
                                   @keyup="loadQuestionSets"
                                   @keypress.enter.prevent="loadQuestionSets"
                                   :placeholder="$t(`Search`)+`. . .`"
                                   ref="filter_name"
                                   id="filter_name" autofocus
                            />
                        </div>
                    </form>
                    <router-link :to="{name: 'company.question.group.create'}" class="button primary-button ml-2">
                        <span class="icon"><i class="eicon e-plus"></i></span>
                        <span class="text-capitalize">{{ $t("add new set") }}</span>
                    </router-link>
                </div>
                <div class="row row-cols-md-4 has-col-gap" v-if="isContentLoading">
                    <div class="col" v-for="index in 8" :key="index">
                        <QuestionSetLoader></QuestionSetLoader>
                    </div>
                </div>
                <template v-else>
                    <h4 class="empty-message" v-if="isEmpty">{{ $t(`No Question Set Found`) }}</h4>
                    <div class="row row-cols-md-4 has-col-gap" v-if="!isEmpty">
                        <div class="col" v-for="group in questionGroups">
                            <div class="question-card">
                                <ul>
                                    <li class="text-break">{{ $t(`Group Name`) }}:
                                        <router-link :to="{name: 'company.question.group.edit', params: {id: group.id}}">{{ group.name }}</router-link>
                                    </li>
                                    <li>{{ $t(`Question Type`) }}: <span>{{ group.exam_type.name }}</span></li>
                                    <li>{{ $t(`Total Question`) }}: <span>{{ group.total_questions }}</span></li>
                                    <li>{{ $t(`Updated On`) }}: <span>{{ group.last_update }}</span></li>
                                </ul>
                                <div class="d-flex mt-auto flex-wrap buttons">
                                    <a href="javascript:void(0)" @click.prevent="duplicateQuestionSet(group.id)" class="control-button control-button--primary has_bg_color">
                                        <div class="control-button__icon">
                                            <i class="eicon e-duplicate"></i>
                                        </div>
                                        <span>{{$t(`Duplicate`)}}</span>
                                    </a>
                                    <router-link :to="{name: 'company.question.group.edit', params: {id: group.id}}" class="control-button control-button--primary has_bg_color">
                                        <div class="control-button__icon"><i class="eicon e-pencil"></i></div>
                                        <span>{{ $t(`Edit`) }}</span>
                                    </router-link>
                                    <a href="javascript:void(0)" @click.prevent="deleteQuestionSet(group.id)" class="control-button control-button--danger has_bg_color">
                                        <div class="control-button__icon"><i class="eicon e-delete"></i></div>
                                        <span>{{ $t(`Delete`) }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

            </section>
        </div>
    </dashboard-layout>
</template>
<script>
const DashboardLayout = () => import("../../layouts/DashboardLayout.vue");
import client from '../../app/api/Client';

const QuestionSetLoader = () => import("../../components/_loaders/QuestionSetLoader.vue");

export default {
    components: {
        DashboardLayout,
        QuestionSetLoader
    },
    data() {
        return {
            isContentLoading: false,
            questionGroups: [],
            filter: {
                name: '',
            }
        }
    },
    computed: {
        isEmpty() {
            return this.questionGroups.length === 0;
        }
    },
    methods: {
        async loadQuestionSets() {
            this.isContentLoading = true;
            try {
                let {data: {data}} = await client().get('/company/question/groups', {params: this.filter});
                this.questionGroups = data;
            } catch (e) {
            }
            this.isContentLoading = false;
        },

        async deleteQuestionSet(id) {
            let message = {
                title: this.$t(`Confirmation`),
                body: this.$t(`Do you really want to delete this Question Set?`)
            };

            this.$dialog.confirm(message).then(() => {
                client().delete(`/company/question/group/${id}`)
                    .then(({data: {data, message}}) => {
                        this.questionGroups = this.questionGroups.filter(qg => qg.id !== id);
                        this.$toast.success(this.$t(message));
                    })
                    .catch(({response: {data}}) => {
                        this.$toast.error(this.$t(data.message));
                    });
            });
        },

        duplicateQuestionSet(questionSetId) {
            let message = {
                title: this.$t("Confirmation"),
                body: this.$t('Are you sure you want to duplicate this question set?')
            };

            this.$dialog.confirm(message).then(() => {
                client().get(`/company/question/group/${questionSetId}/duplicate`).then(({data: {data, message}}) => {
                    this.$toast.success(this.$t(message));
                    this.gotoQuestionSetEditPage(data);
                }).catch(({response: {data}}) => {
                    this.$toast.error(data.message);
                });
            })
        },

        gotoQuestionSetEditPage(questionSet) {
            this.$router.push({
                name: 'company.question.group.edit',
                params: {id: questionSet.id}
            })
        },
    },

    async created() {
        await this.loadQuestionSets();
        await this.$refs.filter_name.focus();
    }
}
</script>

<style scoped>
    .question-card ul {
        list-style: none;
    }
    .question-card .buttons {
        gap: 10px;
    }
    .question-card .buttons .control-button:not(:last-child) {
        margin-right: 0;
    }

</style>
